import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';

export default () => (
  <Layout>
    <Seo
      title="Registration was succesfull"
      description="Just click the link in the confirmation e‑mail we have just sent you."
    />

    <Page type="success">
      <Header closeUrl="/lessons">log in to Pilsner Urquell guide </Header>
      <Content center={true}>
        <h1 className="h2 growing text-transform-uppercase">
          registration <br />
          was succesful!
        </h1>
        <div className="page-text-container">
          <p className="h6">
            Just click the link in the confirmation email we sent you.
          </p>
        </div>
      </Content>
      <Footer>
        <FooterLinkNext to="/login">log in</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)
